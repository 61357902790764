<script>
export default {
  components: {
    FilterDashboard: () => import('./Filter.vue'),
    ChartOne: () => import('./ChartOne.vue'),
    ChartTwo: () => import('./ChartTwo.vue'),
    ChartThree: () => import('./ChartThree.vue'),
  },
}
</script>

<template>
  <div class="pb-10 print">
    <filter-dashboard :class="$vuetify.breakpoint.xs? 'no-print' : $vuetify.breakpoint.sm? 'no-print' : 'filter no-print'"/>
    <h3 class="mt-8 mb-3">Produção de matéria seca</h3>
    <chart-one />
    <h3 class="mt-8 mb-3">Qualidade do pasto</h3>
    <chart-two />
    <h3 class="mt-8 mb-3">Evolução do pasto nos piquetes</h3>
    <chart-three />
  </div>
</template>

<style scoped>
.filter {
  z-index: 3;
  position: fixed;
  top: 70px;
  width: calc(100vw - 40px);
  max-width: 1276px;
}
div >>> .apexcharts-toolbar {
  z-index: 2 !important;
}
</style>
